<template>
  <div class="wrapper wrapper-4">
    <Progress @back="goToStep(2)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать дистанцию" @submit="setValue" @setLs="setLs"
      :id="3">
      <template v-slot:Title>К какой дистанции вы готовитесь?</template>
    </QuestionRadio>
    <div class="wrapper__bg"></div>
  </div>
</template>

<script>
import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_3',
    data() {
      return {
        name: 'experience',
        variants: [
          {
            key: 1,
            title: '5 км'
          },
          {
            key: 2,
            title: '10 км'
          },
          {
            key: 3,
            title: '21 км'
          },
          {
            key: 4,
            title: '40 км'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      values() {
        return this.$store.state.values;
      },
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 3,
          title: 'К какой дистанции вы готовитесь',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 4);
        this.$router.push({ path: '/', query: {step: 4}});
      },
      setLs(value) {
        let stepData = {
          id: 3,
          title: 'К какой дистанции вы готовитесь?',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
