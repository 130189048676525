<template>
  <div class="wrapper wrapper-6">
    <Progress @back="goToStep(4)" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать один вариант" @submit="setValue"
      @setLs="setLs" :id="5">
      <template v-slot:Title>Вы хотите тренироватся:</template>
    </QuestionRadio>
    <div class="wrapper__bg"></div>
  </div>
</template>

<script>
  import QuestionRadio from '@/components/QuestionRadio'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_5',
    data() {
      return {
        name: 'format',
        variants: [
          {
            key: 1,
            title: 'Онлайн'
          },
          {
            key: 2,
            title: 'Офлайн'
          },
        ]
      }
    },
    components: {
      Progress,
      QuestionRadio,
    },
    computed: {
      step() {
        return this.$store.state.step;
      },
      values() {
        return this.$store.state.values;
      },
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 5,
          title: 'Вы хотите тренироватся:',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$store.dispatch('setStep', 6);
        this.$router.push({ path: '/', query: { step: 6 } });
      },
      setLs(value) {
        let stepData = {
          id: 5,
          title: 'Вы хотите тренироватся:',
          answerText: this.variants.filter(v => v.key == value)[0].title,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
      }
    }
  }
</script>
