<template>
	<div class="h-100">
		<div class="scrolled">
			<div class="finish">
				<div class="finish__title"><span>Спасибо за регистрацию <br>на Simple Run!</span></div>
				<div class="finish__text">
					<p>С учетом указанных в анкете данных, мы сформируем и пришлем рекомендации по программам тренировок и специальный подарок от нашей команды</p>
					<p><strong>А пока ты можешь использовать наши инструменты:</strong></p>
					<ul>
						<li>
							<i><img src="../assets/img/icon-2.svg" alt=""></i>
							<span>Трекер</span>
						</li>
						<li>
							<i><img src="../assets/img/icon-1.svg" alt=""></i>
							<span>Метроном бегуна</span>
						</li>
						<li>
							<i><img src="../assets/img/icon-3.svg" alt=""></i>
							<span>Калькуляторы</span>
						</li>
						<li>
							<i><img src="../assets/img/icon-4.svg" alt=""></i>
							<span>Пульсовые зоны</span>
						</li>
						<li>
							<i><img src="../assets/img/icon-5.svg" alt=""></i>
							<span>Интервальный таймер</span>
						</li>
					</ul>
					<p class="small">Все программы тренировок, как всегда, можно найти на сайте simplerun.ru</p>
					<a href="javascript:void(0)" class="button button__registration button__registration--static button__registration--finish" @click="goToApp">Ну наконец-то, бежим дальше!</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'finish',
		data() {
			return {
				password: false,
			}
		},
		computed: {
			values() {
				return this.$store.state.values;
			},
			token() {
				return this.$store.state.token;
			}
		},
		methods: {
			goToApp() {
				document.location.href = `app://form-finish?token=${this.token}`;
			}
		},
	}
</script>

<style>
	.h-100 {
		height: 100%;
	}
	.choose-program__header--2 {
		background-image: url(../assets/img/program-background-2.jpg);
	}
	.choose-program__header--3 {
		background-image: url(../assets/img/program-background-3.jpg);
	}
	.choose-program__header--4 {
		background-image: url(../assets/img/program-background-4.jpg);
	}
	.finish {
		padding-left: 13px;
		padding-right: 13px;
		padding-top: 0px;
		padding-bottom: 20px;
		width: 100%;
		position: relative;
		overflow: hidden;
	}
	.finish__title:before {
		content: '';
		display: block;
		width: 120%;
		height: 56px;
		background-color: #CCFF00;
		position: absolute;
		z-index: 0;
		bottom: -20px;
		left: -10%;
		right: 0;
		margin: auto;
		-webkit-border-radius: 50%;
		-moz-border-radius: 50%;
		border-radius: 50%;
	}
	.finish__title span {
		display: block;
		position: relative;
		z-index: 2;
	}
	.finish__title {
		font-size: 20px;
		position: relative;
		line-height: 1.2;
		font-weight: bold;
		padding: 20px;
		background: #CCFF00;
		margin-left: -13px;
		width: calc(100% + 26px);
		padding-left: 13px;
		padding-right: 13px;
		padding-top: calc(20px + env(safe-area-inset-top));
		padding-bottom: 0px;
	}
	.finish__text strong {
		font-weight: bold;
		font-size: 16px;
	}
	.button__registration--static.button__registration--finish {
		margin-top: 23px !important;
		font-size: 16px !important;
	}
	.finish__text {
    padding-top: 29px;
    font-size: 14px;
    line-height: 1.2;
    padding-right: 6px;
    font-weight: 500;
    letter-spacing: 0.2px;
    width: calc(100% + 10px);
	}
	.finish__text p:last-child {
		margin-bottom: 14px;
	}
	.finish__text p {
		margin-bottom: 20px;
	}
	.finish__text ul li span {
		margin-left: 16px;
	}
	.finish__text ul li {
		display: flex;
		margin-bottom: 2px;
		align-items: center;
	}
	.finish__text ul {
		margin-bottom: 17px;
		margin-top: -7px;
	}
	.button__registration--static {
		position: static !important;
		width: 100% !important;
		display: block !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
		margin-top: 30px !important;
	}
	@media screen and (min-width: 360px) {
		.finish__title {
			font-size: 28px;
			width: calc(100% + 40px);
			margin-left: -20px;
			padding-left: 20px;
		}
		.finish {
			padding-left: 20px;
			padding-right: 20px;
		}
		.finish__text {
			padding-top: 48px;
			font-size: 18px;
			line-height: 1.2;
			padding-right: 6px;
			font-weight: 500;
			letter-spacing: 0px;
			width: 100%;
		}
		.finish__text p {
			margin-bottom: 17px;
		}
		.finish__text ul {
			margin-top: -2px;
		}
		.finish__text ul li {
			font-size: 15px;
		}
		.finish__text ul li {
			margin-bottom: 7px;
		}
		.small {
			font-size: 16px !important;
		}
	}
</style>