import Vue from 'vue'
import VueRouter from 'vue-router'
import Quiz from '../views/Quiz.vue'
import Register from '../views/Register.vue'
import FinishRegister from '../views/FinishRegister.vue'
import Step_0 from '../components/Step_0.vue'
import Step_1 from '../components/Step_1.vue'
import Step_2 from '../components/Step_2.vue'
import Step_3 from '../components/Step_3.vue'
import Step_4 from '../components/Step_4.vue'
import Step_5 from '../components/Step_5.vue'
import Step_6 from '../components/Step_6.vue'
import Step_7 from '../components/Step_7.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Quiz',
    component: Quiz
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/step_0',
    name: 'Step 0',
    component: Step_0
  },
  {
    path: '/step_1',
    name: 'Step 1',
    component: Step_1
  },
  {
    path: '/step_2',
    name: 'Step 2',
    component: Step_2
  },
  {
    path: '/step_3',
    name: 'Step 3',
    component: Step_3
  },
  {
    path: '/step_4',
    name: 'Step 4',
    component: Step_4
  },
  {
    path: '/step_5',
    name: 'Step 5',
    component: Step_5
  },
  {
    path: '/step_6',
    name: 'Step 6',
    component: Step_6
  },
  {
    path: '/step_7',
    name: 'Step 7',
    component: Step_7
  },
  {
    path: '/finish-register',
    name: 'FinishRegister',
    component: FinishRegister
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
