<template>
	<header class="header">
		<div class="header__prev" @click="$emit('back')" v-if="isBack" v-bind:class="{ 'header__prev--black': black }">
			<span class="prev-icon"></span>Назад
		</div>
		<div class="header__progress" v-bind:class="{ 'd-none': displayNone, 'header__progress-item--last': last }">
			<div class="header__progress-item" v-bind:class="{'header__progress-item--last': last }">
				<div class="header__fill" :style="`width: ${width}%`"></div>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'Progress',
	props: {
		black: Boolean,
		displayNone: Boolean,
		last: Boolean,
		isBack: {
			type: Boolean,
			default: true,
			required: false
		},
	},
	computed: {
		step() {
			return this.$store.state.step;
		},
		width() {
			return this.step * 16.7;
		},
	},
}
</script>


<style>
.d-none {
	display: none;
}
</style>