import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

let API_URL = 'https://sr111.05.testing.place/api/v2';

const headers = {
	'Content-Type': 'application/json',
	'app-token': 'secret'
};

const instance = axios.create({
	headers,
});
const notifyAboutError = () => {
	// empty
};

instance.interceptors.response.use(
	response => response,
	(error) => {
		notifyAboutError(error);
		return Promise.reject(error);
	},
);

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		step: 0,
		validation: null,
		token: null,
		validation_error: null,
		version: {},
		values: JSON.parse(localStorage.getItem('qwiz-sr')) || [],
		steps: [],
		coachId: null,
		trainingType: '',
		userId: null,
		userFullName: '',
		userEmail: '',
		userPhone: ''
	},
	actions: {
		skipStep ({ commit }) {
			commit('skip_step')
		},
		setStep ({ commit }, step) {
			commit('set_step', step)
		},
		setStepBack({ commit }) {
			commit('step_back')
		},
		setToken ({ commit }, token) {
			commit('set_token', token)
		},
		setValue ({ commit, state }, stepData) {
			let index = state.values.findIndex(v => v.id == stepData.id);
			if (index !== -1) {
				commit('clear_value', index)
			}
			commit('set_value', stepData)
		},
		removeValue ({ commit }, id) {
			commit('REMOVE_VALUE', id);
		},
		async getVersion ({ commit }) {
			try {
				const version = await instance.get(`${API_URL}/check-version`, { headers: headers })
				commit('set_version', version.data);
			} finally {
				console.log('error')
			}
		},
		async checkUser ({ commit }, userData) {
			commit('set_validation_error', null);
			commit('set_validation', null);
			try {
				const validation = await instance.post(`${API_URL}/register/validate`, userData, { headers: headers })
				commit('set_validation', validation.data);
			} catch(error) {
				commit('set_validation_error', error.response.data);
			}
		},
		async registerUser ({ commit }, userData) {
			commit('set_validation_error', null);
			try {
				const user = await instance.post(`${API_URL}/register`, userData, { headers: headers })
				commit('set_token', user.data.api_token);
			} catch(error) {
				commit('set_validation_error', error.response.data);
			}
		},
		// eslint-disable-next-line no-empty-pattern
		async sendQuiz ({  }, quizData) {
			try {
				await instance.post(`${API_URL}/coaches/quizzes`, quizData, { headers: {
					'Content-Type': 'application/json',
					'app-token': 'secret',
				} })
			} catch(error) {
				console.log(error);
			}
		},
		async sendQuizzes ({ state }, quizData) {
			try {
				await instance.post(`${API_URL}/coaches/quizzes`, quizData, { headers: {
					'Content-Type': 'application/json',
					'app-token': 'secret',
					'Authorization': `Bearer ${state.token}`
				} })
			} catch(error) {
				console.log(error);
			}
		}
	},
	mutations: {
		setCoachId(state, coachId) {
			state.coachId = coachId;
		},
		setTrainingType(state, trainingType) {
			state.trainingType = trainingType;
		},
		setUserId(state, userId) {
			state.userId = userId;
		},
		setUserFullName(state, userFullName) {
			state.userFullName = userFullName;
		},
		setUserEmail(state, userEmail) {
			state.userEmail = userEmail;
		},
		setUserPhone(state, userPhone) {
			state.userPhone = userPhone;
		},
		skip_step (state) {
			state.step++
		},
		step_back (state) {
			state.steps.splice(-1,1)
			let lastStep = state.steps.pop();
			state.step = parseFloat(lastStep)
		},
		REMOVE_VALUE(state, id) {
			state.values = state.values.filter(item => item.id !== id);
		},
		set_step (state, step) {
			state.step = step
			let index = state.steps.includes(parseFloat(step));
			if (!index) {
				state.steps.push(parseFloat(step))
			}
		},
		set_value(state, stepData) {
			// Добавляем новое значение в state.values, избегая дубликатов по id
			const index = state.values.findIndex(item => item.id === stepData.id);
			if (index !== -1) {
				state.values[index] = stepData; // обновляем существующий элемент
			} else {
				state.values.push(stepData); // добавляем новый элемент
			}
		
			// Работа с localStorage
			let qwizSr = JSON.parse(localStorage.getItem('qwiz-sr')) || [];
			const indexInStorage = qwizSr.findIndex(item => item.id === stepData.id);
			if (indexInStorage !== -1) {
				qwizSr[indexInStorage] = stepData; // обновляем существующий элемент
			} else {
				qwizSr.push(stepData); // добавляем новый элемент
			}
			localStorage.setItem('qwiz-sr', JSON.stringify(qwizSr));
		},
		clear_value (state, index) {
			state.values.splice(index, 1);

			const qwizSr = JSON.parse(localStorage.getItem('qwiz-sr'));
			qwizSr.splice(index, 1);
			localStorage.setItem('qwiz-sr', JSON.stringify(qwizSr));
		},
		set_version (state, version) {
			state.version = version;
		},
		set_validation (state, validation) {
			state.validation = validation;
		},
		set_validation_error (state, error) {
			state.validation_error = error;
		},
		set_token (state, token) {
			state.token = token;
		},
	}
})

export default store;