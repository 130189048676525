<template>
  <div class="wrapper wrapper-5">
    <Progress @back="goToStep(3)" />
    <DateField :name="name" error="Нужно указать дату забега" @submit="setValue" :id="4">
      <template v-slot:Title>Укажите дату забега:</template>
    </DateField>
    <div class="wrapper__bg"></div> 
  </div>
</template>

<script>
import DateField from '@/components/DateField'
  import Progress from '@/components/Progress'
  export default {
    name: 'Step_4',
    data() {
      return {
        name: 'date',
        variants: [
          {
            key: 1,
            title: 'Да'
          },
          {
            key: 2,
            title: 'Нет'
          },
        ]
      }
    },
    components: {
      Progress,
      DateField,
    },
    computed: {
      values() {
        return this.$store.state.values;
      },
      step() {
        return this.$store.state.step;
      }
    },
    methods: {
      goToStep(value) {
        this.$store.dispatch('setStepBack', value);
        this.$router.push({ path: '/', query: {step: this.step}});
      },
      setValue(value) {
        let stepData = {
          id: 4,
          title: 'Дата забега',
          answerText: value,
          answer: value,
        }
        this.$store.dispatch('setValue', stepData);
        this.$router.push('setStep', 5);
        this.$router.push({ path: '/', query: { step: 5 } });
      },
    }
  }
</script>
