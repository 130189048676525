<template>
  <div class="wrapper wrapper-3">
    <Progress @back="goToStep(1)" />
    <QuestionCheckbox :variants="variants" :name="name" error="Нужно выбрать одну или несколько целей"
      @submit="setValue" @setLs="setLs" :whiteTitle="true" :id="2">
      <template v-slot:Title>Ваша беговая цель</template>
    </QuestionCheckbox>
    <div class="wrapper__bg"></div> 
  </div>
</template>

<script>
import QuestionCheckbox from '@/components/QuestionCheckbox'
import Progress from '@/components/Progress'
export default {
  name: 'Step_2',
  data() {
    return {
      name: 'experience',
      variants: [
        {
          key: 1,
          title: 'Быть подтянутым, в хорошей форме'
        },
        {
          key: 2,
          title: 'Поддерживать активный образ жизни'
        },
        {
          key: 3,
          title: 'Укрепление здоровья'
        },
        {
          key: 4,
          title: 'Нормализация веса'
        },
        {
          key: 5,
          title: 'Подготовка к дистанции',
        },
        {
          key: 6,
          title: 'Другое',
          input: true
        },
      ],
    }
  },
  components: {
    Progress,
    QuestionCheckbox,
  },
  computed: {
    values() {
      return this.$store.state.values;
    },
    step() {
      return this.$store.state.step;
    }
  },
  methods: {
    goToStep(value) {
      this.$store.dispatch('setStepBack', value);
      this.$router.push({ path: '/', query: { step: this.step } });
    },
    setValue(value) {
      let checked = value.filter(v => v.id === 5).length;
      let stepData = {
        id: 2,
        title: 'Ваша беговая цель',
        answerText: value,
        answer: value,
      }
      this.$store.dispatch('setValue', stepData);
      if (checked > 0 ) {
        this.$store.dispatch('setStep', 3);
        this.$router.push({ path: '/', query: { step: 3 } });
      } else {
          this.$store.dispatch('setStep', 6);
          this.$router.push({ path: '/', query: { step: 6 } });
        
      }
    },
    setLs(value) {
      let stepData = {
        id: 2,
        title: 'Ваша беговая цель',
        answerText: value,
        answer: value,
      }
      this.$store.dispatch('setValue', stepData);
    }
  }
}
</script>
