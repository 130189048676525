<template>
	<main class="content content--center">
		<div class="content__wrapper">
			<h1 class="content__title">
				<slot name="Title"></slot>
			</h1>
			<div class="qwiz-area">
				<div class="content__item">
					<div class="content__sizes content__sizes--center">
						<div class="content__field--wrapper-date">
							<input 
								v-if="!checkboxChecked"
								type="text" 
								class="content--size__field content--size__field-date center-date" 
								placeholder="дд.мм.гггг" 
								v-model="$v.field_1.$model" 
								v-mask="'##.##.####'" 
								inputmode="numeric"
							>
							<input 
								v-else
								type="text" 
								class="content--size__field content--size__field-date center-date" 
								placeholder="дд.мм.гггг" 
								disabled
							>
						</div>
					</div>
				</div>
				<div class="content__item">
					<label class="content__item--radio">
						<input type="checkbox" name="checkbox" v-model="checkboxChecked">
						<span>Я не знаю дату забега</span>
					</label>
				</div>
			</div>
		</div>
		<a href="javascript:void(0)" @click="next()" class="button">Продолжить</a>
	</main>
</template>

<script>
	import Vue from 'vue'
	import { required, minLength } from 'vuelidate/lib/validators'
	import moment from 'moment';
	export default {
		name: 'QuestionRadio',
		data() {
			return {
				field_1: '',
				checkboxChecked: false,
			}
		},
		props: {
			name: String,
			error: String,
		},
		validations() {
			return this.checkboxChecked ? {} : {
				field_1: {
					required,
					minLength: minLength(10),
				},
			}
		},
    computed: {
      values() {
        return this.$store.state.values;
      },
    },
    mounted() {
      if (this.values.filter(v => v.id == 9).length > 0) {
        this.field_1 = this.values.filter(v => v.id == 9)[0].answer;
      }
    },
		methods: {
			next() {
				let today = moment().format('DD.MM.YYYY');
				if (!this.checkboxChecked) {
					let dateFormatted = moment(this.field_1, 'DD.MM.YYYY').format('DD.MM.YYYY');
					if (dateFormatted == 'Invalid date') {
						Vue.$toast.open({
							message: 'Нужно указать корректную дату',
							type: 'error',
							position: 'bottom'
						});
						return false;
					} else {
						if (!moment(this.field_1, 'DD.MM.YYYY').isAfter(moment())) {
							Vue.$toast.open({
								message: 'Дата забега не может быть раньше чем '+today,
								type: 'error',
								position: 'bottom'
							});
							return false;
						}
					}
				}
				this.$v.$touch()
				if (this.$v.$invalid) {
					Vue.$toast.open({
						message: this.error,
						type: 'error',
						position: 'bottom'
					});
				} else {
					this.$emit('submit', this.checkboxChecked ? 'Дата неизвестна' : this.field_1);
				}
			}
		}
	}
</script>