<template>
  <div class="wrapper wrapper-1">
    <Progress :isBack="true" @back="back" />
    <QuestionRadio :variants="variants" :name="name" error="Нужно выбрать возраст" @submit="setValue" @setLs="setLs"
      :id="0">
      <template v-slot:Text>Ответьте, пожалуйста, на несколько вопросов, чтобы мы могли подготовить заявку на персональные тренировки и консультацию с тренером. Это займет не более 3 минут.</template>
      <template v-slot:Title>Ваш возраст:</template>
    </QuestionRadio>
    <div class="wrapper__bg"></div>
  </div>
</template>

<script>
import QuestionRadio from '@/components/QuestionRadio'
import Progress from '@/components/Progress'
export default {
  name: 'Step_0',
  data() {
    return {
      name: 'age',
      variants: [
        {
          key: 1,
          title: 'До 20 лет'
        },
        {
          key: 2,
          title: '20 - 35 лет'
        },
        {
          key: 3,
          title: '35 - 50 лет'
        },
        {
          key: 4,
          title: '50 - 60 лет'
        },
        {
          key: 5,
          title: 'Старше 60 лет',
        },
      ]
    }
  },
  components: {
    Progress,
    QuestionRadio
  },
  computed: {
    step() {
      return this.$store.state.step;
    }
  },
  methods: {
    goToStep(value) {
      this.$store.dispatch('setStepBack', value);
      this.$router.push({ path: '/', query: { step: this.step } });
    },
    setValue(value) {
      let stepData = {
        id: 0,
        title: 'Ваш возраст',
        answerText: this.variants.filter(v => v.key == value)[0].title,
        answer: value,
      }
      this.$store.dispatch('setValue', stepData);
      this.$store.dispatch('setStep', 1);
      this.$router.push({ path: '/', query: { step: 1 } });
    },
    setLs(value) {
      let stepData = {
        id: 0,
        title: 'Ваш возраст',
        answerText: this.variants.filter(v => v.key == value)[0].title,
        answer: value,
      }
      this.$store.dispatch('setValue', stepData);
      
    },
    back() {
      document.location.href = 'app://back-to-app';
    }
  }
}
</script>
