<template>
	<div class="h-100">
		<Step_0 v-if="step == 0" />
		<Step_1 v-if="step == 1" />
		<Step_2 v-if="step == 2" />
		<Step_3 v-if="step == 3" />
		<Step_4 v-if="step == 4" />
		<Step_5 v-if="step == 5" />
		<Step_6 v-if="step == 6" />
		<Step_7 v-if="step == 7" />
	</div>
</template>

<script>
	import Step_0 from '@/components/Step_0';
	import Step_1 from '@/components/Step_1';
	import Step_2 from '@/components/Step_2';
	import Step_3 from '@/components/Step_3';
	import Step_4 from '@/components/Step_4';
	import Step_5 from '@/components/Step_5';
	import Step_6 from '@/components/Step_6';
	import Step_7 from '@/components/Step_7';
	export default {
		name: 'Quiz',
		data() {
			return {
				coach_id: null,
				training_type: null,
				user_id: null,
				user_full_name: null,
				user_email: null,
				user_phone: null,
			}
		},
		computed: {
			values() {
				return this.$store.state.values;
			},
			step() {
				return this.$store.state.step;
			}
		},
		components: {
			Step_0,
			Step_1,
			Step_2,
			Step_3,
			Step_4,
			Step_5,
			Step_6,
			Step_7,
		},
		mounted() {
			if (!this.$store.state.coachId && localStorage.getItem('coachId')) {
				this.$store.commit('setCoachId', localStorage.getItem('coachId'));
			}
			if (!this.$store.state.trainingType && localStorage.getItem('trainingType')) {
				this.$store.commit('setTrainingType', localStorage.getItem('trainingType'));
			}
			if (!this.$store.state.userId && localStorage.getItem('userId')) {
				this.$store.commit('setUserId', localStorage.getItem('userId'));
			}
			if (!this.$store.state.userFullName && localStorage.getItem('userFullName')) {
				this.$store.commit('setUserFullName', localStorage.getItem('userFullName'));
			}
			if (!this.$store.state.userEmail && localStorage.getItem('userEmail')) {
				this.$store.commit('setUserEmail', localStorage.getItem('userEmail'));
			}
			if (!this.$store.state.userPhone && localStorage.getItem('userPhone')) {
				this.$store.commit('setUserPhone', localStorage.getItem('userPhone'));
			}
			const urlParams = new URLSearchParams(window.location.search);
			const coachId = urlParams.get('coach_id');
			const trainingType = urlParams.get('training_type');
			const userId = urlParams.get('user_id');
			const userFullName = urlParams.get('user_full_name');
			const userEmail = urlParams.get('user_email');
			const userPhone = urlParams.get('user_phone');

			if (coachId) {
				this.$store.commit('setCoachId', coachId);
				localStorage.setItem('coachId', coachId);
			}
			if (trainingType) {
				this.$store.commit('setTrainingType', trainingType);
				localStorage.setItem('trainingType', trainingType);
			}
			if (userId) {
				this.$store.commit('setUserId', userId);
				localStorage.setItem('userId', userId);
			}
			if (userFullName) {
				this.$store.commit('setUserFullName', userFullName);
				localStorage.setItem('userFullName', userFullName);
			}
			if (userEmail) {
				this.$store.commit('setUserEmail', userEmail);
				localStorage.setItem('userEmail', userEmail);
			}
			if (userPhone) {
				this.$store.commit('setUserPhone', userPhone);
				localStorage.setItem('userPhone', userPhone);
			}
			if (this.$route.query.gender && this.$route.query.gender == 'men' || this.$route.query.gender == 'women') {
				let value = 1;
				if (this.$route.query.gender == 'women') {
					value = 2;
				}
				if (!this.$route.query.step) {
					this.$store.dispatch('setStep', 0);
					this.$store.dispatch('setStep', 1);
					this.$router.push({ path: '/', query: {step: 1}});
				} else {
					this.$store.dispatch('setStep', this.$route.query.step);
					this.$router.push({ path: '/', query: {step: this.$route.query.step}});
				}
				let stepData = {
					id: 0,
					title: 'Твой пол',
					answerText: value === 1 ? 'Мужчина' : 'Женщина',
					answer: value,
				}
				this.$store.dispatch('setValue', stepData);
			} else {
				if (!this.$route.query.step) {
					this.$store.dispatch('setStep', 0);
					this.$router.push({ path: '/', query: {step: 0}});
				} else {
					this.$store.dispatch('setStep', this.$route.query.step);
					this.$router.push({ path: '/', query: {step: this.$route.query.step}});
				}
			}
		},
		watch: {
			$route(to) {
				if (to.query.step) {
					this.$store.dispatch('setStep', to.query.step);
				}
			}
		}
	}
</script>

<style>
	.h-100 {
		height: 100%;
	}
</style>